<template>
  <section class="relative md:h-screen">
    <img
      class="absolute -left-52 top-0 z-20 h-screen"
      src="../../assets/Pattren.svg"
      alt=""
    />
    <div
      class="container relative h-full mx-auto py-12 px-5 md:px-8 flex items-center md:justify-between flex-wrap md:flex-nowrap"
    >
      <div class="w-full md:w-1/2 tracking-wide leading-relaxed text-right">
        <h3 class="text-black hidden md:block text-xl" data-aos="fade-in">
          من نحن
        </h3>
        <p
          data-aos="fade-in"
          class="text-Gray my-8 text-center md:text-right"
        >
          شركة زين للخدمات التموينية والسفرجة والنظافة هي من احدى الشركات
          الرائدة في هذا المجال بخبرات محلية واجنبية.نحن نعتمد في منهجنا واسلوب
          عملنا على تبادل الثقة والتفاهم و الانسجام لأننا نؤمن إيمانا عميقا بأن
          هذا ما سيؤدي الى تحقيق النجاح وتحقيق المنافع المتبادلة التي من شأنها
          أن تعزز المزيد من الأعمال التجارية بين الطرفين مستقبلا والتي تستمر
          لفترة طويلة.
        </p>
        <router-link
          data-aos="fade-in"
          class="hidden md:flex items-center"
          :to="{ name: 'About' }"
        >
          <span class="mx-2 text-black font-bold  tracking-normal"
            >المزيد عن الشركة</span
          >
          <img
            src="../../assets/long-arrow-pointing-to-the-right.svg"
            alt="قراءة المزيد عن زين"
          />
        </router-link>
        <div class="flex md:hidden justify-center relative z-30">
          <router-link
            :to="{ name: 'About' }"
            class="bg-primary text-white rounded-md px-5 py-2"
          >
            إقرا المزيد
          </router-link>
        </div>
      </div>
      <div
        class="hidden w-1/2 md:relative my-12 md:my-0 md:h-full md:flex items-center justify-center overflow-hidden"
      >
        <img
          class="mx-auto md:mx-0 md:absolute -top-20 -right-20"
          src="../../assets/aboutcad2.png"
          alt=""
        />
        <img
          class="mx-auto md:mx-0 md:absolute -bottom-20 -left-20"
          src="../../assets/aboutcard1.png"
          alt=""
        />
      </div>
    </div>
  </section>

  <section data-aos="fade-left" id="CaroueslSection" class="relative w-full h-auto my-12">
    <div
      class="bg-white md:bg-servCarusoleSection absolute right-section w-1/2 h-full"
    ></div>
    <div
      class="bg-white md:bg-servCarusoleSection container relative z-30 rounded-l-lg mx-auto px-5 md:px-8 py-12"
    >
      <Swiper
        :spaceBetween="50"
        :slidesPerView="slideBrakpoints"
        class="my-8 mx-auto"
        :pagination="{ clickable: true }"
      >
        <SwiperSlide
          v-for="(item, index) in data"
          :key="index"
          class="text-right md:text-center bg-white md:bg-transparent shadow-2xl md:shadow-none rounded-md p-5 font-medium tracking-normal leading-relaxed"
        >
          <img
            class="bg-white rounded-md p-5 md:mx-auto md:shadow-md"
            :src="item.img"
            alt=""
          />
          <h3 class="text-xl my-3 font-bold text-black">{{ item.title }}</h3>
          <p class="text-servicesTextColor md:text-lightGray text-md">
            {{ item.p }}
          </p>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>
<script>
import certificateicon from "../../assets/certificate icon.svg";
import gear from "../../assets/gear.svg";
import rating from "../../assets/rating.svg";
import SwiperCore, { Pagination, Scrollbar } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Pagination, Scrollbar]);

export default {
  name: "",
  data() {
    return {
      data: [
        {
          title: "خبرات محلية واجنبية",
          p:
            "نوفر موظفين مدربين على مهام النظافة والسفرجة وتلتزم الشركة بتحديد مشرفين عليهم من كفاءات محلية واجنبية",
          img: rating,
        },
        {
          title: "شهادات صحية للموظفين",
          p:
            "تلتزم الشركة بتوفير موظفين لائقين بدنيا بزي موحد ولديهم شهادات صحية للقيام بالأعمال المطلوبة من قبل الزبائن ",
          img: certificateicon,
        },
        {
          title: "معدات وموظفون حسب الطلب",
          p:
            "نقوم بتوفير جميع المواد المعدات واللوازم والماكينات التي سيتم استخدامها في عمليات التنظيف",
          img: gear,
        },
      ],
      breakpoints: {
        // 700px and up
        320: {
          itemsToShow: 1,
        },
        768: {
          itemsToShow: 2,
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
        },
      },
    };
  },
  computed: {
    slideBrakpoints() {
      if (window.innerWidth > 1024) {
        return 3;
      } else if (window.innerWidth > 768) {
        return 2;
      } else if (window.innerWidth > 320) {
        return 1;
      }
      return 1;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>
<style lang="scss">
.right-section {
  background-image: url(../../assets/Path926.png) !important;
  background-repeat: no-repeat !important;
  background-position: right -400px top 50% !important;
}

#CaroueslSection .swiper-pagination {
  @media screen and (min-width: 768px){
    display: none;
  }
}
</style>
